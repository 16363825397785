
import { defineComponent, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import EmployeeListModal from "@/layout/header/partials/employeeList/EmployeeListDrawer.vue";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { apiEndpoint } from "@/mixin/apiEndpoint";
import { VueCookieNext } from 'vue-cookie-next';
import { ElNotification } from 'element-plus';
import axios from 'axios';

export default defineComponent({
  mixins: [apiEndpoint],
  name: "single-entity",
  components: {
    Datatable,
    EmployeeListModal,
    ElNotification,
  },

  data() {
    return {
      api_url: "",
      employekey: 0,
      tableHeader: [
        {
          name: "Action",
          key: "actions",
          sortable: true,
          width: '150px'
        },
        {
          name: "Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Designation",
          key: "designation",
          sortable: true,
        },

        {
          name: "Role",
          key: "role",
          sortable: true,
        },

        {
          name: "Mobile",
          key: "mobile",
          sortable: false,
        },
        {
          name: "Email",
          key: "email",
          sortable: false,
        },
        {
          name: "Status",
          key: "status",
          sortable: false,
        },
      ],
      tableColumn: [
        {
          name: "Entity Type",
          key: "entity_type_id",
          sortable: true,
        },
        {
          name: "Industry Sector",
          key: "industry_sector",
          sortable: true,
        },
        {
          name: "Entity Name",
          key: "entity_name",
          sortable: true,
        },
        {
          name: "Entity Short Name",
          key: "entity_short_name",
          sortable: true,
        },
        {
          name: "Description",
          key: "entity_description",
          sortable: true,
        },
        {
          name: "Registration Number",
          key: "registration_number",
          sortable: true,
        },
        {
          name: "Registration Date",
          key: "registration_date",
          sortable: true,
        },
        {
          name: "Registration Authority",
          key: "registration_authority",
          sortable: true,
        },
        {
          name: "Address",
          key: "address",
          sortable: true,
        },
        {
          name: "Postcode",
          key: "postcode",
          sortable: true,
        },
        {
          name: "Division",
          key: "division",
          sortable: true,
        },
        {
          name: "District",
          key: "district",
          sortable: true,
        },
        {
          name: "Sub District",
          key: "sub_district",
          sortable: true,
        },
        {
          name: "Telephone",
          key: "telephone",
          sortable: true,
        },
        {
          name: "Email",
          key: "email",
          sortable: true,
        },
        {
          name: "Fax",
          key: "fax",
          sortable: true,
        },
        {
          name: "Web url",
          key: "web_url",
          sortable: true,
        },
        {
          name: "Is bill author",
          key: "is_bill_author",
          sortable: true,
        },
      ],
      active_status: "",
      load: false,
      summeryload: false,
      empload: false,
      employeeAccess: false,
      lists: [],
      search: "",
      entity_type: "",
      employee_info: "" as any,
      entityInfoID: '' as any,
      countList: [],
      tableData: [],
      updateLog: [],
      data: {},
      tabIndex: ref(0),
      entityInfos: [],
    };
  },
  async created() {
    this.load = true;
    this.emitter.on("employee-data-update-done", async () => {
      this.getEmployee();
    });
    this.api_url = this.VUE_APP_API_URL;
    this.entity_type = VueCookieNext.getCookie('_seip_entity_type');
    this.employee_info = VueCookieNext.getCookie('_seip_user');

    if (this.entity_type == '1004') {
      this.tabIndex = 1,
        this.employeeAccess = true;
    } else if (this.employee_info.employee.entity_organogram[0].entitytyperole.role_name == "Admin") {
      this.employeeAccess = true;
    } else if (this.employee_info.employee.entity_organogram[0].entitytyperole.id == "27") {
      this.employeeAccess = true;
    } else {
      this.employeeAccess = false;
    }
    this.emitter.on("employee-updated", async () => {
      this.getEmployee();
    });
    await this.getEmployee();
    await this.getEntity();
    await this.getUpdateLog();
    await this.getCount();

  },
  methods: {
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('entity_id', this.entityInfoID);
      formData.set('active_status', this.active_status);
      await ApiService.post('report/entity_employee_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async printPdf() {
      let formData = new FormData();
      formData.set('entity_id', this.entityInfoID);
      formData.set('active_status', this.active_status);


      let data = `${this.VUE_APP_API_URL}/api/report/entity_employee_print`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async exportTrainee() {
      let formData = new FormData();
      formData.set('entity_id', this.entityInfoID);
      formData.set('active_status', this.active_status);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/entity_employee_excel`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'EmployeeList.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    Approve() {
      let data = {
        active_status: 1,
      };

      Swal.fire({
        title: "Are you sure you want to approve it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Approved!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update("entity/approve/" + this.entityInfoID, data)
            .then((response) => {
              this.$router.go(0);
              Swal.fire("Approved!", response.data.data, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    async statusCheck() {
      this.emitter.emit("employee-data-update-done");
    },
    Reject() {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: "Are you sure you want to reject it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Rejected!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update("entity/status_change/" + this.entityInfoID, data)
            .then((response) => {
              this.$router.go(0);

              Swal.fire("Rejected!", response.data.data, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    setActiveTab(event) {
      this.tabIndex = parseInt(event.target.getAttribute("data-tab-index"));
    },
    async getCount() {
      this.summeryload = true;
      await ApiService.get("entity/showcount/" + this.entityInfoID)
        .then((response) => {
          this.summeryload = false;
          this.countList = response.data.data;
        })
        .catch((response) => {
          this.summeryload = false;
          console.log(response);
        });
    },
    async getEmployee() {

      this.empload = true;
      this.tableData = [];
      await ApiService.get(
        "entity/entity_employee?entity_id=" +
        this.entityInfoID +
        "&active_status=" +
        this.active_status
      )
        .then((response) => {
          this.empload = false;

          this.tableData = response.data.data;
           Object.assign(this.tableData, this.lists);
          this.employekey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntity() {
      this.load = true;
      await ApiService.get("entity/show/" + this.entityInfoID)
        .then((response) => {
          this.load = false;
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getUpdateLog() {
      await ApiService.get("entity/update_log/" + this.entityInfoID)
        .then((response) => {
          this.updateLog = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    searchItems() {
      if (this.search !== "") {
        let results: never[] = [];
        for (let j = 0; j < this.tableData.length; j++) {
          if (this.searchingFunc(this.tableData[j], this.search)) {
            results.push(this.tableData[j]);
          }
        }
        this.tableData.splice(0, this.tableData.length, ...results);
      } else {
        Object.assign(this.tableData, this.lists);
      }
    },

    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    },

    edit(data) {
      this.emitter.emit("edit-employee-data", data);
    },

    add() {
      this.$router.push({
        name: "entity employee",
        params: { id: this.entityInfoID },
      });
    },

    view(id) {
      this.$router.push({
        name: "entityEmployeeProfiles",
        params: { entity: this.entityInfoID, id: id },
      });
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.empload = true;
          ApiService.delete("configurations/employee/delete/" + `${id}`)
            .then((response) => {
              Swal.fire("Deleted!", response.data.data, "success").then(() => {
                this.emitter.emit("employee-updated", true);
              });
            })
            .catch(({ response }) => {
              console.log(response);
              this.empload = false;

            });
        }
      });
    },
  },
  setup() {
    const route = useRoute();
    const entityInfoID = route.params.id;
    return { entityInfoID };
  },
});
